module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Laschi Hairstylists & Day Spa","short_name":"Laschi Hairstylists & Day Spa","start_url":"/","background_color":"#EFE9E9","theme_color":"#EFE9E9","display":"standalone","icon":"./src/favicon.png","icons":[{"src":"./src/favicon.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"183821687970a3462ed786aa232a565c"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.laschis.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/gatsby-browser.js'),
      options: {"plugins":[],"queryName":"ServiceCategory","templateName":"ServiceCategory","recipe":"hibiscus","tagName":"ServiceCategories","fishermanParentPageType":"Services","orderPath":"order","groupPath":"schedule.name","groupOrderPath":"schedule.order","layoutComponent":"/app/workspaces/v_1/website_envs/merchants_1807/src/components/WebsiteLayout.jsx","instanceId":"gatsby-plugin-fisherman-page-creator_1"},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/gatsby-browser.js'),
      options: {"plugins":[],"queryName":"ServiceItem","templateName":"ServiceItem","recipe":"hibiscus","tagName":"ServiceItems","fishermanParentPageType":"Services","orderPath":"order","layoutComponent":"/app/workspaces/v_1/website_envs/merchants_1807/src/components/WebsiteLayout.jsx","instanceId":"gatsby-plugin-fisherman-page-creator_2"},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/gatsby-browser.js'),
      options: {"plugins":[],"queryName":"TeamMember","templateName":"TeamMember","recipe":"hibiscus","fishermanParentPageType":"Team","layoutComponent":"/app/workspaces/v_1/website_envs/merchants_1807/src/components/WebsiteLayout.jsx","instanceId":"gatsby-plugin-fisherman-page-creator_3"},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-locations/gatsby-browser.js'),
      options: {"plugins":[],"useStorage":true},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-animations/gatsby-browser.js'),
      options: {"plugins":[],"enabled":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P8MQSWP9","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-botpress/gatsby-browser.js'),
      options: {"plugins":[],"botId":"231174b7-5646-43ba-ac65-001eb40b7a1e","clientId":"499419b0-5205-4d0c-a258-994d82c581dd","merchantId":"1807","botpressConfig":{"color":"#DDD2BF","variant":"solid","themeMode":"light","fontFamily":null,"radius":null,"additionalStylesheetUrl":"https://files.bpcontent.cloud/2025/02/26/19/20250226191433-S2BFUNR7.css","botName":"Laschi Hairstylists & Day Spa","composerPlaceholder":null}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
